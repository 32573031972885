import { IDataChart } from '@/model/main/IDataChart';
import { Icon } from '@/model/util/Icon';
import Swal, { SweetAlertInput } from 'sweetalert2';
class Util {
	public dateAndValueToDictionary(labels: Array<string>, values:Array<number>): Array<IDataChart> {
		let i = 0;
		const dic: Array<IDataChart> = [];
		while(i < labels.length){
			const d:IDataChart = {
				date: labels[i],
				value: values[i]
			};
			dic.push(d);
			i+=1;
		}
		dic.sort((a: IDataChart, b: IDataChart) => {
			const c = new Date(a.date);
			const d = new Date(b.date);
			return c.getTime() - d.getTime();
	
		});
		return dic;
	}
	public acumArray(data: Array<number>): Array<number> {
		const min = Math.min(...data);
		const max = data.reduce((acc, current) => acc + current, 0);
		const result: Array<number> = [];
		const test: Array<number> = [];
		let acum = 0;
		for (let i = 0; i < data.length; i++) {
			acum += data[i];
			test.push(acum / (i + 1));
			result.push(acum / (i + 1));
		}
		console.table({
			original: data,
			resultado: result,
			acumulado: test,
		});

		return result;
	}

	public printInformation(text: string): void {
		if (process.env.NODE_ENV !== 'production') {
			console.info(text);
		}
	}
	public async showToast(title: string, icon: Icon) {
		return Swal.fire({
			title,
			icon,
			toast: true,
			position: 'top-end',
			timer: 2500,
			timerProgressBar: true,
			showCancelButton: false,
			showConfirmButton: false,
		});
	}
	public async waitForPromises(
		promises: Array<Promise<Record<string, any>>>
	) {
		return Promise.all(promises);
	}
	public async showMessage(
		message: string,
		icon: Icon,
		title?: string
	) {
		return Swal.fire({
			title: title,
			icon: icon,
			text: message,
			toast: false,
			position: 'center',
			showCancelButton: false,
			showConfirmButton: true,
		});
	}
	public async showConfirmationMessage(
		message: string,
		icon: Icon,
		title?: string
	) {
		return Swal.fire({
			title: title,
			icon: icon,
			text: message,
			toast: false,
			position: 'center',
			showDenyButton: true,
			showConfirmButton: true,
			denyButtonText: 'No',
			confirmButtonText: 'Si, hazlo.',
		});
	}
	public async showInputMessage(
		message: string,
		icon: Icon,
		tipo: SweetAlertInput,
		labelText: string,
		inputPlaceholder: string,
		title?: string
	) {
		return Swal.fire({
			title: title,
			icon: icon,
			text: message,
			toast: false,
			position: 'center',
			showDenyButton: true,
			showConfirmButton: true,
			denyButtonText: 'No',
			confirmButtonText: '!Listo!',
			input: tipo,
			inputValue: '',
			inputLabel: labelText,
			inputPlaceholder: inputPlaceholder,
			inputValidator: (valor) =>
				!valor ? 'Campo obligatorio' : '',
		});
	}
	public isObjetoVacio(elemento: Record<string, any>): boolean {
		return Object.keys(elemento).length === 0 ? true : false;
	}

	public reduceByProperty<T, K extends keyof T>(
		data: Array<T>,
		property: K
	) {
		return data.reduce((acum: any, current: T) => {
			acum[current[property]] = [
				...(acum[current[property]] || []),
				current,
			];
			return acum;
		}, {});
	}

	public replaceTextWith(
		text: string,
		toReplace: string,
		replace: string
	): string {
		return text.replaceAll(toReplace, replace);
	}
	public dateToYYYYMMDD(date: Date): string {
		const year: number = date.getFullYear();
		const month: string =
			date.getMonth() < 9 
				? `0${date.getMonth() + 1}`
				: (date.getMonth() + 1).toString();
		const day: string =
			date.getDate() < 10
				? `0${date.getDate()}`
				: date.getDate().toString();
		return `${year}-${month}-${day}`;
	}

	public dateToDDMMYYYY(date: Date): string {
		const year: number = date.getFullYear();
		const month: string =
			date.getMonth() < 9 
				? `0${date.getMonth() + 1}`
				: (date.getMonth() + 1).toString();
		const day: string =
			date.getDate() < 10
				? `0${date.getDate()}`
				: date.getDate().toString();
		return `${day}-${month}-${year}`;
	}
}

export default new Util();
