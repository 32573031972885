export enum Locales {
    EN = 'en',
    ES = 'es',
    PT = 'pt',
}

export const LOCALES = [
    { value: Locales.EN, caption: 'En' },
    { value: Locales.ES, caption: 'Es' },
    { value: Locales.PT, caption: 'Pt' }
]