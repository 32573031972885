










import { Component, Vue, Prop} from 'vue-property-decorator';

@Component({
	name: 'App',
})
export default class App extends Vue {
	@Prop() readonly keycloak!: string
}
