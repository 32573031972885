import { IToken } from '@/model/login/IToken';
import { IPermission } from '@/model/login/IPermission';
import { IUser } from '@/model/login/IUser';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import { IPayload } from '@/model/login/IPayload';

@Module({ namespaced: true })
export default class LoginStore extends VuexModule {
	public token: IToken | undefined = undefined;
	public permission: Array<IPermission> | undefined = undefined;
	public user: IUser | undefined = undefined;

	@Mutation
	public setToken(payload: IToken) {
		this.token = payload;
	}
	@Mutation
	public setPermission(payload: Array<IPermission>) {
		this.permission = payload;
	}
	@Mutation
	public setUser(payload: IUser) {
		this.user = payload;
	}

	@Action
	public login(payload: IPayload) {
		localStorage.clear();
		localStorage.setItem('TOKEN', payload.token.access_token);
		//console.log("payload", payload)
		localStorage.setItem('client', payload.user.bdName);
		localStorage.setItem('user', payload.user.name);
		this.context.commit('setToken', payload.token);
		this.context.commit('setPermission', payload.permission);
		this.context.commit('setUser', payload.user);
	}

	@Action
	public refreshToken(payload: IToken) {
		//console.log(payload);
		localStorage.setItem('TOKEN', payload.access_token);
		this.context.commit('setToken', payload);
	}
	@Action
	public logout() {
		this.context.commit('setToken', undefined);
		this.context.commit('setPermission', undefined);
		this.context.commit('setUser', undefined);
		localStorage.clear();
	}

	public get getToken(): IToken {
		return this.token as IToken;
	}

	public get getPermission(): Array<IPermission> {
		return this.permission as Array<IPermission>;
	}

	public get getUser(): IUser {
		return this.user as IUser;
	}
}
