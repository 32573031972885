import { ICamping } from '@/model/main/ICamping';
import { IEvCat } from '@/model/main/IEvCat';
import { IFilterData } from '@/model/main/IFilterData';
import Util from '@/utils/Util';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';

@Module({ namespaced: true })
class StoreMain extends VuexModule {
	public filter: IFilterData | undefined = undefined;
	public initEvCat: Array<IEvCat> = [];
	public initICamping: Array<ICamping> | undefined = undefined;

	@Mutation
	public setFilter(payload: IFilterData): void {
		this.filter = payload;
	}

	@Mutation
	public setEvCats(evCats: Array<IEvCat>): void {
		this.initEvCat = evCats;
	}
	@Mutation
	public setCamping(campings: Array<ICamping>): void {
		this.initICamping = campings;
	}

	@Action
	public updateFilter(payload: IFilterData): void {
		this.context.commit('setFilter', payload);
	}

	@Action
	public clearFilter() {
		this.context.commit('setFilter', undefined);
	}

	@Action
	public updateEvCat(evCats: Array<IEvCat>) {
		this.context.commit('setEvCats', evCats);
	}
	@Action
	public updateCamping(campings: Array<ICamping>) {
		this.context.commit('setCamping', campings);
	}

	public get getEvCat(): Array<IEvCat> {
		return this.initEvCat;
	}

	public get getCamping(): Array<ICamping> {
		return this.initICamping as Array<ICamping>;
	}

	public get getFilter(): IFilterData | undefined {
		return this.filter;
	}

	public get getOrderCat(): Array<string> {
		return this.filter!.categories.map((ele): string => {
			return (ele as IEvCat).categoria;
		});
	}
	public get getFilterUrl(): string {
		const filter: Array<string> = [];
		filter.push(
			`FiltrFechDesde=${Util.dateToYYYYMMDD(
				new Date(
					Util.replaceTextWith(
						this.filter!.dateTo.toString(),
						'-',
						'/'
					)
				)
			)}`
		);
		filter.push(
			`FiltrFechHasta=${Util.dateToYYYYMMDD(
				new Date(
					Util.replaceTextWith(
						this.filter!.dateEnd.toString(),
						'-',
						'/'
					)
				)
			)}`
		);
		filter.push(
			`controlSelectCliente=${localStorage.getItem(
				'client'
			)}`
		);
		if (this.getFilter!.camping !== undefined) {
			for (const item of this.filter!.camping) {
				filter.push(
					`FiltrCampings[]=${(item as string)}`
				);
			}
			// filter.push(
			// 	`idSelectCampanaPan=${this.getFilter!.camping}`
			// );
		}
		for (const item of this.filter!.categories) {
			filter.push(
				`FiltrCategs[]=${(item as IEvCat).categoria}`
			);
		}
		return filter.join('&');
	}
}
export default StoreMain;
