import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import vuetify from './plugins/vuetify';
import Keycloak, { KeycloakOnLoad } from 'keycloak-js';

import { messages, defaultLocale } from "@/i18n";

Vue.use(VueI18n)

export const i18n = new VueI18n({
  messages,
  locale: process.env.VUE_APP_I18N_LOCALE || defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLocale
});

// const initConf = {
// 	url: 'http://168.232.167.246:8080/auth',
// 	realm: 'alloxentric',
// 	clientId: 'duoc_workspace',
// 	onLoad: 'login-required',
// };
// const instance = KeyClock(initConf);
// Vue.config.productionTip = false;
// instance.init({ onLoad: initConf.onLoad }).then((authenticated) => {
// 	console.log(authenticated);

// 	if (!authenticated) {
// 		window.location.reload();
// 		console.log('No se pudo cargar F');
// 	} else {
// 		console.log(instance.token);

// new Vue({
// 	router,
// 	store,
// 	vuetify,
// 	render: (h) => h(App),
// }).$mount('#app');
// 	}
// });


Vue.config.productionTip = false

const initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL+'/auth', realm: process.env.VUE_APP_KEYCLOAK_REALM, clientId: process.env.VUE_APP_KEYCLOAK_CLIENT, onLoad: "login-required" as KeycloakOnLoad
}
//console.log("url:",initOptions.url);
const keycloak = Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    console.log("Authenticated");
    //console.log("keycloak", keycloak);
    //store.dispatch("guardarToken", keycloak);
    //const token = keycloak.token
    //console.log("token:", token);

    new Vue({
      el: '#app',
      router,
      store,
      i18n,
      vuetify,
      render: h => h(App, { props: { keycloak: keycloak } })
    })
  }

//Token Refresh
//   setInterval(() => {
//     keycloak.updateToken(70).then((refreshed) => {
      
//       if (refreshed) {
//         console.log('Token refreshed' + refreshed);
//         // store.dispatch("guardarToken", keycloak);
//       } else {
//         // Vue.$log.warn('Token not refreshed, valid for '
//         //   + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
//       }
//     }).catch(() => {
//       console.log('Failed to refresh token');
//     });
//   }, 6000)

}).catch(() => {
	console.log("Authenticated Failed");
});