import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import StoreMain from './main/StoreMain';
import StoreLogin from './login/LoginStore';
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
});

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		storeMain: StoreMain,
		storeLogin: StoreLogin,
	},
	plugins: [vuexLocal.plugin],
});
